<template>
  <v-navigation-drawer
    v-if="isDragAndDropEditor || isRichTextEditor"
    v-model="showHide"
    app
    :temporary="$vuetify.breakpoint.sm"
    :permanent="$vuetify.breakpoint.mdAndUp"
    right
    :width="sidebarWidth"
    :clipped="$vuetify.breakpoint.mdAndUp"
  >
    <template v-slot:prepend>
      <v-tabs
        v-if="isDragAndDropEditor"
        v-model="selectedTab"
        background-color="transparent"
        color="primary"
        grow
        icons-and-text
        hide-slider
        :class="``"
      >
        <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
          :href="`#tab-${tab.value}`"
          :class="`py-2 text-none text-body-2 font-weight-medium  ${
            selectedTab == `tab-${tab.value}`
              ? 'background primary--text'
              : 'secondary--text text--lighten-2 grey lighten-3'
          }`"
          :active-class="``"
        >
          {{ tab.title }}
          <v-icon v-text="tab.icon" class="mb-2" />
        </v-tab>
      </v-tabs>
    </template>

    <TemplateEditorDrawer
      v-if="isRichTextEditor"
      :emptyHTMLTemplate="!selectedTemplate?.html"
      @banner="setBanner"
      @footer="setContentInTheEnd"
      @structure="setContentInTheStart"
      @icon="setContentInEditor"
      @add-content="setContentInEditor"
      @add-button="addButton"
    />

    <v-tabs-items
      v-if="isDragAndDropEditor"
      v-model="selectedTab"
      class="sidebar-container"
    >
      <v-tab-item value="tab-custom" class="sidebar-scroll-content">
        <StylesTab @selected-structure="setSignatureStructure" />
      </v-tab-item>
      <v-tab-item value="tab-info" class="sidebar-scroll-content">
        <v-card flat class="px-2 fill-height overflow-y-auto">
          <TagsDragInDrop />
        </v-card>
      </v-tab-item>
      <v-tab-item value="tab-cta" class="pa-4 sidebar-scroll-content">
        <!-- Itens de marketing aqui:

        <ul>
          <li>Banner</li>
          <li>Botões</li>
          <li>Redes sociais</li>
          <li>etc</li>
        </ul> -->

        <Alert color="info"> Em breve </Alert>
      </v-tab-item>
      <!-- <v-tab-item
          v-for="tab in tabs"
          :key="tab.value"
          :value="'tab-' + tab.value"
        >
          <v-card flat>
            <v-card-text>{{ text }}</v-card-text>
          </v-card>
        </v-tab-item> -->
    </v-tabs-items>
  </v-navigation-drawer>
</template>
<script>
import TagsDragInDrop from "@/components/sign/new_users_signature/TagsDragInDrop.vue";
import TemplateEditorDrawer from "@/components/sign/TemplateEditorDrawer";
import StylesTab from "@/components/sign/new_users_signature/StylesTab.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "EditorToolsSidebar",

  components: { TagsDragInDrop, TemplateEditorDrawer, StylesTab },

  data() {
    return {
      selectedTab: 1,
      structure: "",
      tab: null,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      tabs: [
        {
          icon: "mdi-palette-advanced",
          title: "Aparência",
          value: "custom",
        },
        {
          icon: "mdi-badge-account",
          title: "Informações",
          value: "info",
        },
        {
          icon: "mdi-bullhorn",
          title: "Marketing",
          value: "cta",
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      "selectedTemplate",
      "richTextEditorRef",
      "showEditorSidebar",
    ]),

    isRichTextEditor() {
      return this.selectedTemplate.editor
        ? this.selectedTemplate.editor === "rich_text"
        : !this.selectedTemplate.editor;
    },

    isDragAndDropEditor() {
      return this.selectedTemplate.editor
        ? this.selectedTemplate.editor === "drag_and_drop"
        : !this.selectedTemplate.editor;
    },

    showHide: {
      get() {
        return this.showEditorSidebar;
      },
      set(new_val) {
        this.setSignEditorSidebar(new_val);
      },
    },

    sidebarWidth() {
      if (this.$vuetify.breakpoint.xl) {
        return 460;
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        return 400;
      } else {
        return "75%";
      }
    },
  },

  methods: {
    ...mapMutations(["setSignatureStructure", "setSignEditorSidebar"]),

    /* RICH TEXT EDITOR ACTIONS */

    /**
     * Function to add a banner in the end of the signature
     * @param {String} banner the new element to add
     */
    setBanner(banner) {
      this.setContentInTheEnd(banner);
    },

    /**
     * Function to add content in the end of current html
     * @param {String} element the new element to add
     */
    setContentInTheEnd(element) {
      const currentContent = this.richTextEditorRef.getContent();
      const html = currentContent + element;
      this.editor.setContent(html);
    },

    /**
     * Function to add content in the start of current html
     * @param {String} element the new element to add
     */
    setContentInTheStart(element) {
      const currentContent = this.richTextEditorRef.getContent();
      const html = element + currentContent;
      this.richTextEditorRef.setContent(html);
    },

    /**
     * Function to add content in the last cursor position
     * @param {String} element the new element to add
     */
    setContentInEditor(element) {
      this.richTextEditorRef.focus();
      this.richTextEditorRef.insertContent(element);
    },

    addButton(button) {
      this.setContentInEditor(button);
    },
  },
};
</script>
<style>
.sidebar-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.sidebar-scroll-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
</style>
