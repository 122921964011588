<template>
  <v-overlay
    :value="true"
    opacity="1"
    z-index="200"
    color="primary darken-1"
    class="text-center"
  >
    <v-progress-circular width="10" size="110" color="white" indeterminate>
      <Logo product="conecta_sign" height="55" noMargins white class="blink" />
    </v-progress-circular>
    <div class="text-h6 font-weight-regular pt-6" v-html="signLoadingMessage" />
  </v-overlay>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "SignLoading",

  computed: {
    ...mapGetters(["templateEditorLoading", "signLoadingMessage"]),
  },
};
</script>
