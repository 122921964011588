<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <span>
        <slot name="dialog-activator" />
      </span>

      <v-btn tile icon v-bind="attrs" v-on="on">
        <v-icon>mdi-link</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 mb-3"> Criar link </v-card-title>

      <v-form v-model="valid" class="px-3">
        <v-row class="ma-0 pa-0">
          <v-col cols="12" class="py-1">
            <v-text-field
              v-model="localText"
              label="Texto do link"
              placeholder="Insira o texto que representa o link"
              filled
              :rules="[required]"
              disabled
            />
          </v-col>
          <v-col cols="12" class="py-1">
            <LinkInput
              v-model="localLink"
              filled
              :applied-link="localLink"
              label="Link"
              background-color="grey lighten-3"
              :placeholder="'Insira uma URL ou uma TAG que represente uma URL'"
              @update="localLink = $event"
              @validation="linkIsValid = $event"
            />
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-checkbox
              v-model="newTabLink"
              dense
              hide-details
              label="Abrir link em outra guia"
            />
          </v-col>
        </v-row>
      </v-form>

      <v-card-actions class="mt-5">
        <v-row class="ma-0 pa-0">
          <v-col
            :order="$vuetify.breakpoint.sm ? 2 : 1"
            cols="12"
            md="6"
            sm="12"
            :class="`${$vuetify.breakpoint.sm ? 'd-flex justify-center' : ''}`"
          >
            <v-btn
              color="secondary lighten-2"
              class="text-none text-body-1 font-weight-medium px-0"
              text
              @click="cancel"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col
            :order="$vuetify.breakpoint.sm ? 1 : 2"
            cols="12"
            md="6"
            sm="12"
            :class="`${
              $vuetify.breakpoint.sm
                ? 'd-flex justify-center'
                : 'd-flex justify-end'
            }`"
          >
            <v-btn
              class="text-none text-body-1 font-weight-medium px-6"
              color="primary"
              :disabled="!valid || !linkIsValid"
              @click="confirmLink"
            >
              Inserir link
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import LinkInput from "@/components/forms/inputs/LinkInput";

export default {
  name: "CreateLinkDialog",

  components: { LinkInput },

  props: {
    openNewTab: { type: Boolean, default: true },
    link: { type: String, default: "" },
    text: { type: String, default: "" },
    styles: { type: String, default: "" },
  },

  data() {
    return {
      dialog: false,

      newTabLink: true,

      linkIsValid: true,

      localLink: "",
      localText: "",

      tagFinder: new RegExp("\\[\\*.+?\\*\\]", "g"),

      required: (e) => !!e || "Insira um texto para representar o texto",

      valid: true,
    };
  },

  computed: {
    isTagLink() {
      return this.localLink.match(this.tagFinder);
    },

    linkSettings() {
      return `href="${this.localLink}"`;
    },

    linkStyle() {
      return `style="${this.styles}"`;
    },
  },

  watch: {
    openNewTab() {
      this.newTabLink = this.openNewTab;
    },

    link() {
      this.localLink = this.link;
    },

    text() {
      this.localText = this.text;
    },

    dialog() {
      this.reset();
    },
  },

  methods: {
    confirmLink() {
      let link = `<a ${this.linkSettings} ${
        this.newTabLink ? 'target="_blank"' : ""
      }>
        <p ${this.linkStyle}>
        ${this.localText}
        </p>
      </a>`;

      this.$emit("update:link", link);

      this.dialog = false;
    },

    cancel() {
      this.clear();
      this.dialog = false;
    },

    clear() {
      this.newTabLink = false;
      this.linkIsValid = false;
      this.localLink = "";
      this.localText = "";
    },

    reset() {
      this.newTabLink = this.openNewTab;
      this.localLink = this.link.match(this.tagFinder)
        ? this.link.match(this.tagFinder)
        : this.link;
      this.localText = this.text;
    },
  },

  beforeMount() {
    this.reset();
  },
};
</script>
