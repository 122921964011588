<template>
  <div>
    <table class="grey lighten-2" style="width: 100%; min-height: 300px">
      <tbody>
        <tr>
          <td
            style="
              width: 30%;
              height: 100%;
              border: 2px dashed #ccc !important;
              margin: 10px;
            "
            class="image-drag-area"
            id="image"
          >
            <draggable
              class="tags-drag-area image-drag-area list-group pa-2"
              style="min-height: 100%"
              :list="draggableImages"
              group="image"
              :key="updated"
            >
              <DragAndDropItem
                v-for="(element, index) in draggableImages"
                :key="index"
                :index="index"
                :html="element"
                :tag="getTextBetweenHtmlTag(element, true)"
                @update:html="setStyle(index, $event, true)"
                @remove="remove(index, true)"
              />
            </draggable>
          </td>
          <td
            style="
              width: 60%;
              height: 100%;
              border: 2px dashed #ccc !important;
              margin: 10px;
            "
            class="text-drag-area"
            id="content"
          >
            <draggable
              class="tags-drag-area list-group pa-2"
              style="min-height: 100%"
              :list="draggableTexts"
              group="tags"
              :key="updated"
            >
              <DragAndDropItem
                v-for="(element, index) in draggableTexts"
                :key="index"
                :index="index"
                :html="element"
                :tag="getTextBetweenHtmlTag(element)"
                @update:html="setStyle(index, $event)"
                @remove="remove(index)"
              />
            </draggable>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import DragAndDropItem from "@/components/sign/signature/new_editor/DragAndDropItem.vue";

import {
  getTableColumnsContent,
  getDraggableElementsByType,
  getTextBetweenHtmlTag,
} from "@/helpers/services/tagPreview";

import { mapGetters } from "vuex";

export default {
  name: "LeftStructure",

  components: {
    draggable,
    DragAndDropItem,
  },

  props: {},

  data() {
    return {
      draggableTexts: [],
      draggableImages: [],
      imageColWidth: 0,
      updated: 0,
    };
  },

  computed: {
    ...mapGetters(["selectedTemplate"]),

    localHtml() {
      return this.mountHtml();
    },
  },

  watch: {
    async html() {
      await this.getSavedElements();
    },

    localHtml() {
      this.mountHtml();
    },

    updated() {
      this.mountHtml();
    },

    draggableImages() {
      this.mountHtml();
    },

    draggableTexts() {
      this.mountHtml();
    },
  },

  methods: {
    getTextBetweenHtmlTag,

    emitHtml(html) {
      this.$emit("selected:structure", "left");
      this.$emit("update:html", html);
    },

    getSavedElements() {
      const tags = getTableColumnsContent(this.selectedTemplate.html) || [];

      this.setElementsByType(tags);
    },

    remove(index, image = false) {
      if (image) {
        this.draggableImages.splice(index, 1);
      } else {
        this.draggableTexts.splice(index, 1);
      }
    },

    setElementsByType(tags) {
      if (tags.length > 0) {
        const elementsByType = getDraggableElementsByType(tags);

        this.draggableImages = elementsByType.images || [];
        this.draggableTexts = elementsByType.texts || [];
      }
    },

    setStyle(index, element, image = false) {
      if (image) {
        this.draggableImages[index] = element;
      } else {
        this.draggableTexts[index] = element;
      }

      this.updated++;
    },

    mountHtml() {
      let imageHtml = this.draggableImages.join("");
      let textHtml = this.draggableTexts.join("");

      const html = `<table style="width:100%;max-width:800px">
        <tr>
          <td style="width:${
            this.imageColWidth > 0 ? this.imageColWidth + "px" : "20%"
          }" id="image-area">
            ${imageHtml}
          </td>
          <td style="width:80%" id="text-area">
            ${textHtml}
          </td>
        </tr>
      </table>`;

      this.emitHtml(html);
    },
  },

  async beforeMount() {
    await this.getSavedElements();
  },
};
</script>
