<template>
  <HeaderDialog
    :show="showHide"
    width="700"
    id="reapply-model"
    :title="templateName"
    subtitle="Aplicar modelo de assinatura para usuários"
    color="primary"
    :loading="loading"
    :disabled-action="loading || localLoading"
    action-text="Salvar e aplicar modelo"
    body-class="px-0"
    button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
    :persistent="false"
    @close="showHide = false"
    @action="reapply"
  >
    <template v-slot:body>
      <v-subheader class="font-weight-medium px-1 mt-2 pt-0">
        Usuários que irão receber a assinatura de e-mail
      </v-subheader>

      <UsersPicker
        :selected="selected"
        :loading="loading"
        placeholder="Selecionar usuários para aplicar assinatura"
        filled
        label=""
        multiple
        show-email-settings
        show-missing-tags-count
        search
        @update="selected = $event"
      />

      <NotifyUsersSwitch
        :value="needToNotifyUsers"
        :disabled="selected.length === 0"
        subtitle="Enviar e-mail notificando os usuários sobre a nova assinatura de e-mail aplicada"
        title="Notificar alteração de assinatura para todos os usuários"
        tooltip-text="Usuários configurados como restritos continuarão precisando aceitar aplicação do modelo de assinatura."
        @update="needToNotifyUsers = $event"
      />
    </template>
  </HeaderDialog>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import NotifyUsersSwitch from "@/components/forms/buttons/NotifyUsersSwitch.vue";
import UsersPicker from "@/components/forms/field_pickers/UsersPicker";

export default {
  name: "ReaplyUsersSignatureDialog",

  props: {},

  components: { NotifyUsersSwitch, UsersPicker },

  data() {
    return {
      impactedUsers: [],
      localLoading: false,
      selected: [],
      needToNotifyUsers: false,
    };
  },

  computed: {
    ...mapGetters([
      "loading",
      "selectedTemplate",
      "selectedTemplateId",
      "showReaplyDialog",
      "users",
      "selectedTemplateNewHtml",
    ]),

    showHide: {
      get() {
        return this.showReaplyDialog;
      },
      set(new_value) {
        this.setReaplySignatureDialog(new_value);
      },
    },

    templateName() {
      return this.selectedTemplate ? this.selectedTemplate.name : "";
    },
  },

  watch: {
    selected() {
      this.getImpactedUsers();
    },

    selectedTemplate() {
      if (this.selectedTemplate) {
        this.reset();
      }
    },

    showHide() {
      if (this.showHide) {
        this.reset();
      }
    },
  },

  methods: {
    ...mapActions(["applyTemplate", "updateSignatureTemplate"]),
    ...mapMutations([
      "setReaplySignatureDialog",
      "setTemplateEditorLoading",
      "setSignLoadingMessage",
      "setAppliedSignatureTemplateStatus",
    ]),

    getImpactedUsers() {
      this.impactedUsers = this.users.reduce(
        (acc, { applied_signature_key, email }) => {
          if (this.selectedTemplateId === applied_signature_key) {
            acc.push(email);
          }
          return acc;
        },
        []
      );
    },

    getApplyPayload() {
      return {
        emails: this.selected,
        template_key: this.selectedTemplateId,
        notify_users: this.needToNotifyUsers,
        hide_snackbar: true,
      };
    },

    getSaveChangesPayload() {
      if (this.selectedTemplate) {
        let { id, name, description, editor } = this.selectedTemplate;

        return {
          id_template: id,
          html: this.selectedTemplateNewHtml,
          name,
          description,
          editor,
          hide_snackbar: true,
        };
      }
      return false;
    },

    async reapply() {
      this.localLoading = true;

      const templatePayload = this.getSaveChangesPayload();
      const reaplyPayload = this.getApplyPayload();

      await this.updateSignatureTemplate(templatePayload).then(() => {
        this.setApplyLoading();

        this.applyTemplate(reaplyPayload);

        setTimeout(() => {
          this.resetApplyLoading();
        }, 4000);

        setTimeout(() => {
          this.setAppliedSignatureTemplateStatus(false);
        }, 20000);
      });
    },

    reset() {
      this.getImpactedUsers();

      if (this.impactedUsers.length > 0) {
        this.selected = this.impactedUsers.slice();
      } else {
        this.selected = [];
      }
    },

    resetApplyLoading() {
      this.setTemplateEditorLoading(false);
      this.setSignLoadingMessage("");
      this.setAppliedSignatureTemplateStatus(true);
    },

    setApplyLoading() {
      this.localLoading = false;
      this.showHide = false;

      this.setTemplateEditorLoading(true);
      this.setSignLoadingMessage("Aplicando modelo...");
    },
  },

  beforeMount() {
    this.reset();
  },
};
</script>
