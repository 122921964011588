<template>
  <v-container>
    <!-- <PillTabs
      :selected="selectedTab"
      :tabs="tabs"
      @update="selectedTab = $event"
    /> -->

    <v-card-text>
      <SignatureStructures
        @set-structure="$emit('selected-structure', $event)"
        inline
      />
    </v-card-text>
  </v-container>
</template>
<script>
/* import PillTabs from "@/components/general/PillTabs.vue"; */
import SignatureStructures from "@/components/sign/signature/SignatureStructures";

export default {
  name: "StyleTab",

  components: { /* PillTabs, */ SignatureStructures },

  data() {
    return {
      selectedTab: 0,
      tabs: [
        {
          icon: "mdi-view-dashboard-outline",
          text: "Layout",
          value: "layout",
        },
        /* {
          icon: "mdi-brush-outline",
          text: "Tema",
          value: "theme",
        }, */
      ],
    };
  },

  methods: {},
};
</script>
