<template>
  <v-tabs
    v-model="selectedTab"
    hide-slider
    centered
    small
    :color="color"
    class="rounded-pill my-5 px-4"
  >
    <v-tab
      v-for="(tab, index) in tabs"
      :key="index"
      class="rounded-pill text-none text-body-2 font-weight-medium px-5 py-2"
      active-class="rounded-pill primary white--text"
    >
      <v-icon v-if="tab.icon" v-text="tab.icon" left />
      {{ tab.text }}
    </v-tab>
  </v-tabs>
</template>
<script>
export default {
  name: "PillTabs",

  props: {
    color: { type: String, default: "primary" },
    selected: { type: [String, Number], default: 0 },
    tabs: { type: Array, required: true },
  },

  data() {
    return {
      selectedTab: 0,
    };
  },

  watch: {
    selected() {
      this.selectedTab = this.selected;
    },

    selectedTab() {
      this.$emit("update", this.selectedTab);
    },
  },

  beforeMount() {
    this.selectedTab = this.selected;
  },
};
</script>
