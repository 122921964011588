<template>
  <v-list-item
    class="px-0 my-5 no-hover"
    link
    :ripple="false"
    :disabled="disabled"
    @click="notify = !notify"
  >
    <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">
      <v-avatar :color="notify ? 'lightSign' : 'grey lighten-5'">
        <v-icon
          v-text="notify ? 'mdi-bell-ring-outline' : 'mdi-bell-outline'"
          :color="notify ? 'primary' : 'grey'"
        />
      </v-avatar>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title
        class="font-weight-medium text-wrap break-word text-sm-body-1"
      >
        {{ title }}

        <v-tooltip v-if="!!tooltipText" top max-width="300">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="info"
              v-bind="attrs"
              v-on="on"
              size="18"
              class="ml-1"
              v-text="'mdi-help-circle-outline'"
            />
          </template>
          <span>
            {{ tooltipText }}
          </span>
        </v-tooltip>
      </v-list-item-title>
      <v-list-item-subtitle v-if="subtitle" class="text-wrap break-word mt-1">
        {{ subtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <v-switch
        :input-value="notify"
        @click.stop="notify = !notify"
        hide-details
        inset
      />
    </v-list-item-action>
  </v-list-item>
</template>
<script>
export default {
  name: "NotifyUsersSwitch",

  props: {
    disabled: { type: Boolean, default: false },
    subtitle: { type: String, default: "" },
    title: { type: String, required: true },
    tooltipText: { type: String, default: "" },
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      notify: false,
    };
  },

  watch: {
    notify() {
      this.$emit("update", this.notify);
    },

    value() {
      this.reset();
    },
  },

  methods: {
    reset() {
      this.notify = this.value;
    },
  },

  beforeMount() {
    this.reset();
  },
};
</script>
