<template>
  <SignLoading v-if="showLoading || templateEditorLoading" />

  <v-container
    v-else
    fluid
    :class="`${
      $vuetify.breakpoint.smAndDown ? 'px-2 overflow-x-hidden' : ''
    } fill-height py-0 align-start pa-0 ma-0`"
    style="height: 100%"
  >
    <EditorToolsSidebar />

    <v-container class="overflow-y-auto">
      <v-skeleton-loader v-if="showLoading" type="table-heading" class="mt-4" />

      <!-- ALERTA PARA DISPOSITIVOS PEQUENOS -->
      <v-alert
        v-if="$vuetify.breakpoint.xs"
        color="error"
        dark
        class="font-weight-medium"
      >
        A tela do seu dispositivo é muito pequena para criar/editar um modelo,
        considere utlizar este editor em um dispositivo desktop.
      </v-alert>

      <!-- EDITOR / VISUALIZADOR -->

      <v-row v-if="!$vuetify.breakpoint.xs" no-gutters>
        <v-col cols="12">
          <EditorTabs
            :hide-tabs="!isRichTextEditor && !isDragAndDropEditor"
            :html="selectedTemplateHtml"
          >
            <v-form class="pa-0 mb-0 white" style="width: 100%">
              <RichTextEditor
                v-if="isRichTextEditor"
                :html="selectedTemplateHtml"
                :disabled="templateEditorLoading"
                @preview="showPreview = true"
              />

              <DragAndDropEditor
                v-else-if="isDragAndDropEditor"
                :html="selectedTemplateHtml"
                :disabled="templateEditorLoading"
                @preview="showPreview = true"
              />

              <HtmlSimpleEditor
                v-else
                :placeholder="'Insira o código da sua assinatura HTML aqui'"
                :html="selectedTemplateHtml"
                :disabled="templateEditorLoading"
                :height="'400px'"
              />
            </v-form>
          </EditorTabs>
        </v-col>
      </v-row>

      <!-- DIALOG: DESATIVAR O MODELO PARA NOVOS USUARIOS -->
      <!-- <ConfirmationDialog
        :show="newUserModelDialog"
        action-text="Desmarcar modelo de novos usuários"
        message="Você tem certeza que deseja desmarcar este <b>modelo de assinatura para os novos usuários?</b> Sem ele, será necessário aplicar manualmente a assinatura de todos os novos colaboradores."
        @close="cancelLeaveNewUserDialog()"
        @ok="confirmCloseNewUserDialog()"
      /> -->

      <!-- DIALOG: CONFIRMAR EXCLUSÃO DE UM TEMPLATE -->
      <!-- <v-dialog v-model="dialogDeleteTemplate" width="500">
        <v-card-title primary-title>Confirmar exclusão</v-card-title>
        <v-card-text>Você realmente deseja excluir o template? </v-card-text>
        <v-card-text v-if="setIsNewUserModel"
          ><v-alert text prominent color="warning">
            Essa assinatura de e-mail é usada para todos os novos colaboradores.
            Sem ela será necessário a aplicação manualmente de assinatura para
            todos os novos usuários. Caso queira manter um modelo inicial
            deve-se selecionar um novo modelo.
          </v-alert></v-card-text
        >
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="secondary"
            text
            @click="dialogDeleteTemplate = false"
            >Cancelar</v-btn
          >
          <v-btn
            :disabled="loading"
            :loading="loading"
            text
            @click="exclude(template_to_delete)"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-dialog> -->

      <!-- ALTERAÇÔES NÂO SALVAS  -->
      <HeaderDialog
        :show="showConfirmChanges"
        title="Você tem alterações não salvas"
        :showCancel="true"
        @close="showConfirmChanges = false"
        @action="
          showConfirmChanges = false;
          next(true);
        "
        actionText="Sair sem salvar"
        closeText="Continuar editando"
        :disabledAction="false"
      >
        <template v-slot:body>
          <p class="mt-3">Deseja sair do editor sem salvar as alterações?</p>
        </template>
      </HeaderDialog>

      <!-- NOVAS ASSINATURAS PARA USUÁRIOS -->
      <NewUsersTemplateSignature
        v-if="templateData.id"
        :show="showNewUsersTemplateDialog"
        :template-id="templateData.id"
        @close="showNewUsersTemplateDialog = false"
      />

      <ReaplyUsersSignatureDialog />
    </v-container>
  </v-container>
</template>

<script>
import DragAndDropEditor from "@/components/sign/signature/new_editor/DragAndDropEditor.vue";
import HtmlSimpleEditor from "@/components/sign/signature/new_editor/HtmlSimpleEditor.vue";

import { fluxStringToTemplate } from "@/helpers/services/tagPreview";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";

import { mapActions, mapGetters, mapMutations } from "vuex";

import NewUsersTemplateSignature from "@/components/sign/new_users_signature/NewUsersTemplateSignature";
import EditorToolsSidebar from "@/components/sign/new_users_signature/EditorToolsSidebar.vue";

import RichTextEditor from "@/components/sign/signature/new_editor/RichTextEditor.vue";
import EditorTabs from "@/components/sign/signature/new_editor/EditorTabs";

import ReaplyUsersSignatureDialog from "@/components/sign/new_users_signature/ReaplyUsersSignatureDialog";
import SignLoading from "@/components/sign/general/SignLoading.vue";

export default {
  name: "SignEditor",

  components: {
    ReaplyUsersSignatureDialog,
    NewUsersTemplateSignature,
    EditorTabs,
    EditorToolsSidebar,
    HtmlSimpleEditor,
    DragAndDropEditor,
    RichTextEditor,
    SignLoading,
  },

  data() {
    return {
      showNewUsersTemplateDialog: false,
      dialogDeleteTemplate: false,
      template_to_delete: null,
      templateData: {},
      showLoading: false,
      currentSelected: [],
      showDialog: false,
      loadingApplySignature: false,
      showConfirmChanges: false,
      next: () => {},
      newUserModelDialog: false,
      notifyUsers: false,

      html: "",
    };
  },

  computed: {
    ...mapGetters([
      "loading",
      "company",
      "users",
      "templateEditorLoading",
      "selectedTemplateNewHtml",
      "selectedTemplateHtml",
      "selectedTemplate",
      "showPreview",
    ]),

    isRichTextEditor() {
      if (
        !this.selectedTemplate.editor ||
        this.selectedTemplate.editor === "rich_text"
      ) {
        return true;
      }
      return false;
    },

    isDragAndDropEditor() {
      if (
        !this.selectedTemplate.editor ||
        this.selectedTemplate.editor === "drag_and_drop"
      ) {
        return true;
      }
      return false;
    },

    setIsNewUserModel() {
      return this.selectedTemplate.id == this.company.new_users_signature;
    },

    impactedUsers() {
      const template_id = this.$route.params.key;
      return this.users.reduce(
        (emails, { applied_signature_key, email }) =>
          template_id === applied_signature_key ? emails.concat(email) : emails,
        []
      );
    },

    hasChanges() {
      return (
        fluxStringToTemplate(this.selectedTemplate.html).length !==
        fluxStringToTemplate(this.selectedTemplateNewHtml).length
      );
    },
  },
  watch: {
    selectedTemplateNewHtml() {
      if (this.hasChanges) {
        window.addEventListener("beforeunload", this.beforeUnloadHandler);
      } else if (!this.hasChanges) {
        window.removeEventListener("beforeunload", this.beforeUnloadHandler);
      }
    },
  },

  methods: {
    ...mapActions([
      "setSignatureToUsers",
      "getUsers",
      "setNewUsersTemplate",
      "excludeSignatureTemplate",
      "getSignatureById",
    ]),

    ...mapMutations(["setSnackBar"]),

    beforeUnloadHandler(event) {
      event.preventDefault();
      event.returnValue = "";
    },

    async exclude(template_to_delete) {
      await this.excludeSignatureTemplate(template_to_delete).then(() => {
        this.redirectToAllTemplates();
      });
      this.dialogDeleteTemplate = false;
    },

    cancelLeaveNewUserDialog() {
      this.newUserModelDialog = false;
    },

    confirmCloseNewUserDialog() {
      if (this.newUserModelDialog) {
        this.newUserModelDialog = false;
        this.setNewUsersTemplate(null);
      }
    },

    async reapplyTemplate() {
      this.loadingApplySignature = true;

      let payload = {
        emails: this.currentSelected,
        template_key: this.templateData.id,
        notify_users: this.notifyUsers,
      };

      await this.setSignatureToUsers(payload)
        .then(() => {
          this.setSnackBar({
            color: "success",
            message: successMessages.set_signature_to_users,
            show: true,
          });
        })
        .catch((error) => {
          this.setSnackBar({
            color: "error",
            message: errorMessages.set_signature_to_users,
            show: true,
          });
          console.error("reapplyTemplate(): ", error);
        })
        .finally(() => {
          this.loadingApplySignature = false;
          this.showDialog = false;
        });
    },

    redirectToTemplates() {
      this.$router.push({ name: "SignTemplates" });
    },

    resetHtml() {
      this.html = this.selectedTemplateNewHtml;
    },
  },

  beforeRouteLeave(to, from, next) {
    this.next = next;
    if (this.hasChanges) {
      this.showConfirmChanges = true;
    } else {
      this.next(true);
    }
  },

  async beforeMount() {
    this.showLoading = true;

    await this.getSignatureById(this.$route.params.key).catch(({ error }) => {
      console.error(error);
      this.redirectToTemplates();
    });

    this.getUsers();

    this.currentSelected = this.impactedUsers;
    this.resetHtml();
    this.showLoading = false;
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeUnloadHandler);
  },
};
</script>
