<template>
  <v-expansion-panel>
    <v-expansion-panel-header
      :expand-icon="!hasSignatureWarnings ? '' : 'mdi-chevron-down'"
    >
      <v-card-title
        :class="`px-0 ${
          hasMissingTags ? 'error--text' : 'primary--text'
        } pt-2 pb-4`"
      >
        Informações relevantes para uma assinatura

        <span v-if="hasMissingTags" class="ml-2">
          {{ recommendedTagsMissingLabel }}
        </span>
      </v-card-title>
    </v-expansion-panel-header>

    <v-expansion-panel-content class="mb-0 pb-0">
      <v-row class="ma-0 pa-0">
        <v-col
          cols="12"
          sm="12"
          md="6"
          v-for="({ status, title, subtitle }, index) in listOfTips"
          :key="index"
          class="pa-0"
        >
          <v-hover v-slot="{ hover }">
            <v-list-item two-line class="py-0 px-2">
              <v-list-item-action class="mr-4 ml-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      :color="checkItemBackgroundColor(subtitle, status)"
                      size="45"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        v-text="checkItemIcon(subtitle, status)"
                        :color="checkItemColor(subtitle, status)"
                        size="30"
                      />
                    </v-avatar>
                  </template>
                  <span>
                    {{ checkItemStatusLabel(subtitle, status) }}
                  </span>
                </v-tooltip>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  class="text-wrap break-word font-weight-medium"
                >
                  {{ title }}
                </v-list-item-title>
                <v-list-item-subtitle
                  :class="`caption mt-1 text-wrap break-word font-weight-medium normal-line-height`"
                >
                  <span v-if="!status">
                    Insira a TAG <b>{{ subtitle }}</b> no modelo</span
                  >
                  <span v-else>
                    {{ checkItemStatusLabel(subtitle, status) }}
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-overlay
                v-if="checkIfLogoIsEmpty(subtitle, status)"
                absolute
                color="white"
                :value="hover"
                class="hover-action d-flex justify-center"
                width="100%"
              >
                <v-btn
                  color="accent"
                  class="text-none text-body-1 font-weight-medium my-3 mx-auto"
                  :to="{ name: 'SignTags' }"
                  target="_blank"
                >
                  <v-icon v-text="'mdi-cogs'" left />
                  Corrigir

                  <v-icon v-text="'mdi-open-in-new'" right small />
                </v-btn>
              </v-overlay>
            </v-list-item>
          </v-hover>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import { mapGetters } from "vuex";

import { verifyIfHasATag } from "@/helpers/services/tagPreview";

export default {
  name: "RecommendedTagsPanel",

  props: {
    hasSignatureWarnings: { type: Boolean, default: false },
  },

  data() {
    return {
      signatureTags: {
        "[*USER_FULL_NAME*]": false,
        "[*USER_PHOTO*]": false,
        "[*USER_PHONE*]": false,
        "[*USER_POSITION*]": false,
        "[*USER_DEPARTAMENT*]": false,
        "[*COMPANY_LOGO_URL*]": false,
        "[*COMPANY_FULL_ADDRESS*]": false,
      },

      tagsMissingCount: 0,
      tagsOnTemplate: 0,
    };
  },

  computed: {
    ...mapGetters(["companyLogo", "selectedTemplateNewHtml"]),

    hasMissingTags() {
      return this.recommendedTagsMissingNumber > 0;
    },

    listOfTips() {
      return [
        {
          title: "Nome completo",
          subtitle: "[*USER_FULL_NAME*]",
          status: this.signatureTags["[*USER_FULL_NAME*]"],
        },

        {
          title: "Imagem do usuário",
          subtitle: "[*USER_PHOTO*]",
          status: this.signatureTags["[*USER_PHOTO*]"],
        },

        {
          title: "Telefone do usuário",
          subtitle: "[*USER_PHONE*]",
          status: this.signatureTags["[*USER_PHONE*]"],
        },

        {
          title: "Cargo do usuário",
          subtitle: "[*USER_POSITION*]",
          status: this.signatureTags["[*USER_POSITION*]"],
        },

        {
          title: "Departamento do usuário",
          subtitle: "[*USER_DEPARTAMENT*]",
          status: this.signatureTags["[*USER_DEPARTAMENT*]"],
        },

        {
          title: "Logotipo da empresa",
          subtitle: "[*COMPANY_LOGO_URL*]",
          status: this.signatureTags["[*COMPANY_LOGO_URL*]"],
        },
        {
          title: "Endereço completo da empresa",
          subtitle: "[*COMPANY_FULL_ADDRESS*]",
          status: this.signatureTags["[*COMPANY_FULL_ADDRESS*]"],
        },
      ];
    },

    recommendedTagsMissingNumber() {
      return this.tagsMissingCount;
    },

    recommendedTagsMissingLabel() {
      const number = this.recommendedTagsMissingNumber;

      return `(${number} ${number === 1 ? "ausente" : "ausentes"})`;
    },

    signatureTemplate() {
      return this.selectedTemplateNewHtml;
    },
  },

  watch: {
    signatureTemplate() {
      this.reset();
    },
  },

  methods: {
    checkItemBackgroundColor(value, status) {
      if (value === "[*COMPANY_LOGO_URL*]") {
        if (this.companyLogo && status) {
          return "green lighten-4";
        } else if (!this.companyLogo && status) {
          return "orange lighten-4";
        } else {
          return "red lighten-4";
        }
      } else {
        return status ? "green lighten-4" : "red lighten-4";
      }
    },

    checkItemColor(value, status) {
      if (value === "[*COMPANY_LOGO_URL*]") {
        if (this.companyLogo && status) {
          return "success";
        } else if (!this.companyLogo && status) {
          return "warning";
        } else {
          return "error";
        }
      } else {
        return status ? "success" : "error";
      }
    },

    checkItemIcon(value, status) {
      if (value === "[*COMPANY_LOGO_URL*]") {
        if (this.companyLogo && status) {
          return "mdi-check";
        } else if (!this.companyLogo && status) {
          return "mdi-alert-outline";
        } else {
          return "mdi-close";
        }
      } else {
        return status ? "mdi-check" : "mdi-close";
      }
    },

    checkItemStatusLabel(value, status) {
      if (value === "[*COMPANY_LOGO_URL*]") {
        if (this.companyLogo && status) {
          return `${value} já está presente na assinatura`;
        } else if (!this.companyLogo && status) {
          return `${value} está presente porém seu valor está vazio`;
        } else {
          return `${value} está ausente na assinatura`;
        }
      } else {
        return status
          ? `${value} já está presente na assinatura`
          : `${value} está ausente na assinatura`;
      }
    },

    checkIfLogoIsEmpty(value, status) {
      if (value === "[*COMPANY_LOGO_URL*]") {
        if (this.companyLogo && status) {
          return false;
        } else if (!this.companyLogo && status) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },

    getTagsMissingNumber() {
      return Object.values(this.signatureTags).reduce((acc, tip) => {
        if (!tip) {
          acc += 1;
        }

        return acc;
      }, 0);
    },

    verifyIfHasCompanyAddress() {
      return verifyIfHasATag(
        this.signatureTemplate,
        "[*COMPANY_FULL_ADDRESS*]"
      );
    },

    verifyIfHasCompanyLogo() {
      return verifyIfHasATag(this.signatureTemplate, "[*COMPANY_LOGO_URL*]");
    },

    verifyIfHasUseDepartament() {
      return verifyIfHasATag(this.signatureTemplate, "[*USER_DEPARTAMENT*]");
    },

    verifyIfHasUseNameTag() {
      return verifyIfHasATag(this.signatureTemplate, "[*USER_FULL_NAME*]");
    },

    verifyIfHasUsePhone() {
      return verifyIfHasATag(this.signatureTemplate, "[*USER_PHONE*]");
    },

    verifyIfHasUsePhoto() {
      return (
        verifyIfHasATag(this.signatureTemplate, "[*USER_PHOTO*]") ||
        verifyIfHasATag(this.signatureTemplate, "[*USER_SIGN_PHOTO*]")
      );
    },

    verifyIfHasUsePosition() {
      return verifyIfHasATag(this.signatureTemplate, "[*USER_POSITION*]");
    },

    reset() {
      this.signatureTags = {
        "[*USER_FULL_NAME*]": this.verifyIfHasUseNameTag(),
        "[*USER_PHOTO*]": this.verifyIfHasUsePhoto(),
        "[*USER_PHONE*]": this.verifyIfHasUsePhone(),
        "[*USER_POSITION*]": this.verifyIfHasUsePosition(),
        "[*USER_DEPARTAMENT*]": this.verifyIfHasUseDepartament(),
        "[*COMPANY_LOGO_URL*]": this.verifyIfHasCompanyLogo(),
        "[*COMPANY_FULL_ADDRESS*]": this.verifyIfHasCompanyAddress(),
      };

      this.tagsMissingCount = this.getTagsMissingNumber();
    },
  },

  beforeMount() {
    this.reset();
  },
};
</script>
<style scoped>
.hover-action {
  position: absolute;
  z-index: 400;
  opcatiy: 0.9;
}

.hover-action {
  transition: opacity 0.4s ease-in-out;
}
</style>
