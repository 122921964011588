<template>
  <v-expand-x-transition>
    <v-card tile outlined class="mb-2 list-group-item pt-2" height="100%">
      <v-list-item
        style="border: 2px dashed #ccc !important"
        class="align-center mx-2"
      >
        <v-list-item-content>
          <v-list-item-title
            style="pointer-events: none !important"
            v-html="updatedHtml"
          />
        </v-list-item-content>
        <v-list-item-action v-if="!isImage">
          <v-btn icon @click="remove">
            <v-icon v-text="'mdi-trash-can-outline'" />
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <v-card-actions class="my-2 pa-0 mx-0 justify-center">
        <ImageToolbarMenu
          v-if="isImage"
          :styles="elementStyle"
          :element="html"
          :tag="tag"
          @update="$emit('update:html', $event)"
          @remove="remove"
        />

        <TextToolbarMenu
          v-else
          :text="tag"
          :styles="elementStyle"
          :key="elementStyle"
          :html="html"
          @update="emitElementStyle"
          @update:link="$emit('update:html', $event)"
        />
      </v-card-actions>
    </v-card>
  </v-expand-x-transition>
</template>
<script>
import TextToolbarMenu from "@/components/sign/new_users_signature/TextToolbarMenu.vue";
import ImageToolbarMenu from "@/components/sign/new_users_signature/ImageToolbarMenu.vue";

import {
  getElementStyle,
  setElementStyle,
  fluxTemplateToPreview,
} from "@/helpers/services/tagPreview";

export default {
  name: "DragAndDropItem",

  components: { ImageToolbarMenu, TextToolbarMenu },

  props: {
    index: { type: [Number, String], required: true },
    html: { type: String, required: true },
    tag: { type: String, required: true },
  },

  data() {
    return {
      styles: "",
    };
  },

  computed: {
    isImage() {
      return this.html.includes("src=");
    },

    elementStyle: {
      get() {
        return this.styles;
      },
      set(new_style) {
        this.styles = new_style;
      },
    },

    updatedHtml() {
      return fluxTemplateToPreview(this.html);
    },
  },

  watch: {
    html() {
      this.reset();
    },
  },

  methods: {
    emitElementStyle(styles) {
      const values = Object.values(styles).filter((element) => !!element);
      const stringStyle = values.join(";");
      this.elementStyle = stringStyle;

      const elementWithNewStyle = setElementStyle(
        this.updatedHtml,
        stringStyle
      );

      this.$emit("update:html", elementWithNewStyle);
    },

    remove() {
      this.$emit("remove", this.index);
    },

    reset() {
      this.elementStyle = getElementStyle(this.updatedHtml);
    },
  },

  beforeMount() {
    this.reset();
  },
};
</script>
