import { render, staticRenderFns } from "./DragAndDropItem.vue?vue&type=template&id=562c2bb0"
import script from "./DragAndDropItem.vue?vue&type=script&lang=js"
export * from "./DragAndDropItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports