<template>
  <v-form class="px-0 mb-0" style="width: 100%" :disabled="disabled">
    <v-skeleton-loader
      v-if="localLoading"
      class="mx-auto"
      max-width="300"
      type="card"
    />

    <Editor
      v-else
      :html="html"
      :disabled="disabled"
      :height="'42vh'"
      @editor-ref="setRichTextEditorRef"
      @update="updatedHtml = $event"
      @preview="showPreview = true"
    />
  </v-form>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { hasChanges } from "@/helpers/services/utils";

import Editor from "@/components/sign/general/Editor.vue";

export default {
  name: "RichTextEditor",

  components: {
    Editor,
  },

  props: {
    disabled: { type: Boolean, default: false },
    html: { type: String, default: "" },
  },

  data() {
    return {
      localLoading: false,
    };
  },

  computed: {
    ...mapGetters(["selectedTemplateNewHtml"]),

    updatedHtml: {
      get() {
        return this.selectedTemplateNewHtml;
      },
      set(new_html) {
        if (hasChanges(this.selectedTemplateNewHtml, new_html)) {
          this.updateSelectedTemplateHtml(new_html);
        }
      },
    },
  },

  methods: {
    ...mapMutations([
      "setLoading",
      "setRichTextEditorRef",
      "updateSelectedTemplateHtml",
    ]),
  },

  mounted() {
    this.setLoading(false);

    this.localLoading = false;
  },

  beforeMount() {
    this.localLoading = true;
  },
};
</script>
