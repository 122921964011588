<template>
  <div>
    <table
      class="grey lighten-2"
      style="width: 100%; min-height: 300px"
      border="0"
      id="signature-body"
    >
      <tbody>
        <tr style="border: 2px dashed #ccc !important; margin: 10px">
          <td
            style="
              height: 100%;
              border: 2px dashed #ccc !important;
              margin: 10px;
            "
            class="any-element-drag-area pa-2"
          >
            <draggable
              class="tags-drag-area list-group pa-2"
              style="min-height: 100%"
              :list="draggableTags"
              group="tags"
              :key="updated"
            >
              <DragAndDropItem
                v-for="(element, index) in draggableTags"
                :key="index"
                :index="index"
                :html="element"
                :tag="getTextBetweenHtmlTag(element)"
                @update:html="setStyle(index, $event)"
                @remove="remove(index)"
              />
            </draggable>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import DragAndDropItem from "@/components/sign/signature/new_editor/DragAndDropItem.vue";

import {
  getCenterContentElements,
  getTextBetweenHtmlTag,
} from "@/helpers/services/tagPreview";
import { mapGetters } from "vuex";

export default {
  name: "CenterStructure",

  components: {
    draggable,
    DragAndDropItem,
  },

  props: {},

  data() {
    return {
      draggableTags: [],

      updated: 0,
    };
  },

  computed: {
    ...mapGetters(["selectedTemplate"]),

    localHtml() {
      return this.mountHtml();
    },
  },

  watch: {
    async html() {
      await this.getSavedElements();
    },

    localHtml() {
      this.mountHtml();
    },

    updated() {
      this.mountHtml();
    },

    draggableTags() {
      this.mountHtml();
    },
  },

  methods: {
    getTextBetweenHtmlTag,

    emitHtml(html) {
      this.$emit("selected:structure", "center");
      this.$emit("update:html", html);
    },

    getSavedElements() {
      const tags = getCenterContentElements(this.selectedTemplate.html) || [];

      if (tags) {
        this.draggableTags = tags;
      }
    },

    remove(index) {
      this.draggableTags.splice(index, 1);
    },

    setStyle(index, element) {
      this.draggableTags[index] = element;

      this.updated++;
    },

    mountHtml() {
      let tagsHtml = this.draggableTags.join("");

      const html = `<table style="width:100%;max-width:800px">
        <tr>
          <td style="" id="tags-area">
            ${tagsHtml}
          </td>
        </tr>
      </table>`;

      this.emitHtml(html);
    },
  },

  async beforeMount() {
    await this.getSavedElements();
  },
};
</script>
