<template>
  <v-container>
    <LeftStructure
      v-if="leftStructureSelected"
      :html="html"
      @selected:structure="setSelectedTemplateStructure"
      @update:html="updatedHtml = $event"
    />

    <RightStructure
      v-else-if="rightStructureSelected"
      :html="html"
      @selected:structure="setSelectedTemplateStructure"
      @update:html="updatedHtml = $event"
    />

    <CenterStructure
      v-else-if="centerStructureSelected"
      :html="html"
      @selected:structure="setSelectedTemplateStructure"
      @update:html="updatedHtml = $event"
    />
  </v-container>
</template>
<script>
import LeftStructure from "@/components/sign/signature/structures/LeftStructure.vue";
import RightStructure from "@/components/sign/signature/structures/RightStructure.vue";
import CenterStructure from "@/components/sign/signature/structures/CenterStructure.vue";

import { mapGetters, mapMutations } from "vuex";
import { hasChanges } from "@/helpers/services/utils";

export default {
  name: "DragAndDropEditor",

  components: {
    CenterStructure,
    LeftStructure,
    RightStructure,
  },

  props: {
    html: { type: String, default: "" },
  },

  computed: {
    ...mapGetters(["selectedTemplateNewHtml", "signatureStructure"]),

    centerStructureSelected() {
      return this.signatureStructure === "center";
    },

    leftStructureSelected() {
      return this.signatureStructure === "left";
    },

    rightStructureSelected() {
      return this.signatureStructure === "right";
    },

    updatedHtml: {
      get() {
        return this.selectedTemplateNewHtml;
      },
      set(new_html) {
        if (hasChanges(this.selectedTemplateNewHtml, new_html)) {
          this.updateSelectedTemplateHtml(new_html);
        }
      },
    },
  },

  methods: {
    ...mapMutations([
      "setSelectedTemplateStructure",
      "updateSelectedTemplateHtml",
    ]),
  },
};
</script>
<style>
.tags-drag-area:empty::before {
  display: flex;
  justify-content: center;
  text-align: center !important;
  color: var(--v-secondary-lighten3);
  font-style: italic;
}

.text-drag-area .tags-drag-area:empty::before {
  content: "Arraste os elementos de texto para esta área" !important;
}

.image-drag-area .tags-drag-area:empty::before {
  content: "Arraste os elementos de imagem para esta área" !important;
}

.any-element-drag-area .tags-drag-area:empty::before {
  content: "Arraste os elementos para esta área" !important;
}
</style>
