<template>
  <v-toolbar dense flat class="pa-0 mx-0">
    <v-select
      v-model="textValues.font_family"
      :items="dropdown_font"
      label="Selecionar fonte"
      hide-details
      class="pa-0 ma-0 rounded-0"
      dense
      solo
      flat
      style="width: 160px"
      @change="$emit('update', textValues)"
    >
      <template v-slot:selection="data">
        <span :style="data.item.value">{{ data.item.text }}</span>
      </template>
      <template v-slot:item="data">
        <span :style="data.item.value">{{ data.item.text }}</span>
      </template>
    </v-select>

    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-select
        v-model="textValues.font_size"
        :items="fontSize"
        label="Estilo do texto"
        hide-details
        class="pa-0 mx-2 rounded-0"
        overflow
        dense
        solo
        flat
        style="width: 150px"
        @change="$emit('update', textValues)"
      >
        <template v-slot:item="data">
          <span :style="data.item.value">{{ data.item.text }}</span>
        </template>
      </v-select>

      <v-divider vertical></v-divider>

      <v-spacer></v-spacer>

      <v-btn-toggle
        v-model="textValues.font_weight"
        color="primary"
        dense
        group
        @change="$emit('update', textValues)"
      >
        <v-btn :value="'font-weight:600'" text>
          <v-icon>mdi-format-bold</v-icon>
        </v-btn>
      </v-btn-toggle>

      <v-btn-toggle
        v-model="textValues.font_style"
        color="primary"
        dense
        group
        @change="$emit('update', textValues)"
      >
        <v-btn :value="'font-style:italic'" text>
          <v-icon>mdi-format-italic</v-icon>
        </v-btn>
      </v-btn-toggle>

      <v-btn-toggle
        v-model="textValues.text_decoration"
        color="primary"
        dense
        group
        @change="$emit('update', textValues)"
      >
        <v-btn :value="'text-decoration:underline'" text>
          <v-icon>mdi-format-underline</v-icon>
        </v-btn>
      </v-btn-toggle>

      <v-btn :value="4" text disabled>
        <v-icon>mdi-format-color-fill</v-icon>
      </v-btn>

      <!-- <ColorPicker
        :selected="textValues.color"
        mode="icon"
        label="Selecionar cor do texto"
        title="Cor do texto"
        @update="setColor"
      /> -->

      <CreateLinkDialog
        :text="text"
        :styles="styles"
        :link="elementLink"
        @update:link="$emit('update:link', $event)"
      />

      <div class="mx-4"></div>

      <v-btn-toggle
        v-model="textValues.text_align"
        color="primary"
        dense
        group
        @change="$emit('update', textValues)"
      >
        <v-btn :value="'text-align:left'" text>
          <v-icon>mdi-format-align-left</v-icon>
        </v-btn>

        <v-btn :value="'text-align:center'" text>
          <v-icon>mdi-format-align-center</v-icon>
        </v-btn>

        <v-btn :value="'text-align:right'" text>
          <v-icon>mdi-format-align-right</v-icon>
        </v-btn>
      </v-btn-toggle>
    </template>
  </v-toolbar>
</template>
<script>
/* import ColorPicker from "@/components/forms/field_pickers/ColorPicker.vue"; */
import CreateLinkDialog from "@/components/forms/inputs/CreateLinkDialog";

import { getElementLink } from "@/helpers/services/tagPreview";

export default {
  name: "TextToolbarMenu",

  components: { CreateLinkDialog },

  props: {
    inline: { type: Boolean, default: false },
    html: { type: String, default: "" },
    styles: { type: String, required: true },
    text: { type: String, default: "" },
  },

  data() {
    return {
      dropdown_font: [
        { text: "Arial", value: "font-family:Arial;" },
        { text: "Calibri", value: "font-family:Calibri" },
        { text: "Courier", value: "font-family:Courier" },
        { text: "Roboto", value: "font-family:Roboto;" },
        { text: "Verdana", value: "font-family:Verdana" },
      ],
      fontSize: [
        { text: "Título 1", value: "font-size:28px" },
        { text: "Título 2", value: "font-size:24px" },
        { text: "Título 3", value: "font-size:22px" },
        { text: "Título 4", value: "font-size:20px" },
        { text: "Texto normal", value: "font-size:16px" },
        { text: "Texto pequeno", value: "font-size:12px" },
      ],
      menu: false,
      textValues: {
        font_size: "",
        font_family: "",
        color: "",
        font_weight: "",
        font_style: "",
        text_decoration: "",
        text_align: "",
      },
      translateElements: {
        font_size: "font-size",
        font_family: "font-family",
        color: "color",
        font_weight: "font-weight",
        font_style: "font-style",
        text_decoration: "text-decoration",
        text_align: "text-align",
      },
      toggle_exclusive: null,
      toggle_multiple: [],
    };
  },

  computed: {
    elementLink() {
      return getElementLink(this.html);
    },
  },

  watch: {
    "textValues.color"() {
      this.$emit("update", this.textValues);
    },

    styles() {
      if (this.styles) {
        this.textValues = this.formatStyles();
      }
    },
  },

  methods: {
    setColor(color) {
      this.textValues.color = `color:${color}`;
    },

    formatStyles() {
      const keys = [
        "font_size",
        "font_family",
        "color",
        "font_weight",
        "font_style",
        "text_decoration",
        "text_align",
      ];

      const values = this.styles.split(";");

      return keys.reduce((acc, key) => {
        const itemToSearch = this.translateElements[key];

        const hasThisValue = values.find((item) => item.includes(itemToSearch));

        acc[key] = hasThisValue || "";

        return acc;
      }, {});
    },
  },

  beforeMount() {
    if (this.styles) {
      this.textValues = this.formatStyles();
    }
  },
};
</script>
